// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./reducers/auth";
import filters from "./reducers/filters";
import cart from "./reducers/cart";

const rootReducer = combineReducers({
  auth,
  filters,
  cart,
});

export default rootReducer;
