// REDUX ACTION TYPES
export const ACTION_TYPES = {
  // auth
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  UPDATE_USER: "UPDATE_USER",
  CHECK_REMENBERED_USER_DONE: "CHECK_REMENBERED_USER_DONE",
  // layout
  HANDLE_CONTENT_WIDTH: "HANDLE_CONTENT_WIDTH",
  HANDLE_MENU_COLLAPSED: "HANDLE_MENU_COLLAPSED",
  HANDLE_MENU_HIDDEN: "HANDLE_MENU_HIDDEN",
  HANDLE_RTL: "HANDLE_RTL",
  // filters
  UPDATE_FILTERS: "UPDATE_FILTERS",
  // cart
  INCREASE_QUANTITY: "INCREASE_QUANTITY",
  DECREASE_QUANTITY: "DECREASE_QUANTITY",
  GET_ALL_PRODUCT: "GET_ALL_PRODUCT",
  GET_NUMBER_CART: "GET_NUMBER_CART",
  ADD_CART: "ADD_CART",
  UPDATE_CART: "UPDATE_CART",
  DELETE_CART: "DELETE_CART",
  REMOVE_CART: "REMOVE_CART",
};
