import React from "react";
// ** Scroll bar
import "simplebar/src/simplebar.css";

// ** Yup intl message
import "./configs/yupConfig";

// *Map Style
import "leaflet/dist/leaflet.css";

// ** Lazy image
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";

import ReactDOM from "react-dom";
import { lazy, Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import LoadingScreen from "@components/LoadingScreen";

// ** Yup intl message
import "./configs/yupConfig";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/store";

// ** Contexts
import { SettingsProvider } from "@utility/contexts/SettingsContext";
import { CollapseDrawerProvider } from "@utility/contexts/CollapseDrawerContext";
import { IntlProviderWrapper } from "@utility/contexts/Internationalization";

// ** font
import "./index.css";
import "./font/BMDANIEL-Bold.ttf";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

// ----------------------------------------------------------------------

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<LoadingScreen />}>
      <HelmetProvider>
        <SettingsProvider>
          <CollapseDrawerProvider>
            <IntlProviderWrapper>
              <LazyApp />
            </IntlProviderWrapper>
          </CollapseDrawerProvider>
        </SettingsProvider>
      </HelmetProvider>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
